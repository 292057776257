$button-border: $black;
$button-active: $blue_light;

/* Buttons */
.button {
    background-color: $white;
    box-shadow: inset 0 0 0 3px $button-border, 0 3px 0 $button-border;
    display: block;
    font-weight: $ft-weight-bold;
    font-size: 1.5rem;
    padding: 20px;
    margin: 20px 0;
    text-align: center;
    text-decoration: none;

    &:hover {
        box-shadow: inset 0 0 0 3px $button-border;
        transform: translateY(3px);
    }
}

.button.disabled {
    box-shadow: inset 0 0 0 4px $gray;
    color: $gray;
    cursor: not-allowed;
    text-decoration: none;
}

.button.button-link {
    box-shadow: none;
    display: block;
    padding: 1em;
    margin: 2em 0;
    text-decoration: underline;
}

.button.button-inline {
    display: inline-block;
}

.button-login {
    align-items: center;
    display: flex;

    a {
        border: 2px solid $black;
        display: block;
        padding: 4px 10px !important;
    }

    a:hover {
		background-color: transparent !important;
		text-decoration: underline;
	}

    em {
        font-style: normal;
        padding-right: 10px;
    }
}

.back-button {
    background-image: $svg-arrow-left;
    background-repeat: no-repeat;
    background-size: 15px 16px;
    background-position: left 20px center;
    padding-left: 45px;
    margin-top: 40px;
}

.back-button,
.filter-back-button {
    align-items: center;
    display: inline-flex;

    svg {
        width: 30px;
        margin-right: 15px;
    }

    &:hover {
        .button-label {
            text-decoration: underline;
        }
    }
}

.button-search {
    svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }
}

.close-button {
    // background-color: $white;
    box-shadow: inset 0 0 0 3px $black;
    color: $black;
    text-align: center;
    cursor: pointer;
    line-height: 30px;
    width: 30px;
}

.close-button:hover {
    background-color: $blue_light;
    color: $black;
}

.filter-nav-multi {
    margin: 1em 0;
    position: relative;
    z-index: z-index(navigation);

    form {
        margin: 0.5em 0;
    }

    .last-form-field {
        align-items: center;
        display: flex;
        flex: 1 1 auto;
        justify-content: flex-end;
    }

    .last-form-field > *:last-child {
        margin-left: .65rem;
    }

    .custom-select {
        margin: 0;
    }

    .form-field,
    [type=submit] {
        margin: 0.5em 0;
    }

    .button,
    [type=submit] {
        // font-size: .9rem; // 18px
        padding-bottom  : .92em;
        padding-top: .92em;
        min-height: 60px;
    }

    &[aria-hidden=true] {
        display: none;
    }

    @media only screen and (min-width: $viewport-large) {
        flex: 1 1 auto;
        form {
            align-items: center;
            display: flex;
            flex: 1 1 auto;
            justify-content: flex-end;
        }
        
        .form-field+.form-field {
            margin-left: .65rem;
        }

        .button,
        [type=submit] {
            margin: 0;
        }

        .last-form-field {
            margin: 0 0 0 auto;
        }

        .last-form-field > * {
            margin-left: .65rem;
        }

        &[aria-hidden=true] {
            display: block;
        }
    }
}

#filter_button {
    display: none;
}

@media only screen and (min-width: $viewport-large) {
    #filter_button {
        display: none;
    }
}
