#main-footer {
	background: transparent url('./../images/bb-pattern.gif') 0 0 repeat;
    background-size: 1280px;
    border-top: 6px solid $black;
	margin-top: 60px;
	overflow: hidden;
	padding: 15px 0;
}

.social-links {
	display: flex;
	justify-content: center;

	a {
		display: block;
		padding-right: 10px;
		text-decoration: none;
	}
}

.partners {
	display: flex;
	justify-content: flex-end;
	margin: 2rem 0;

	a {
		display: block;
		text-decoration: none;
	}

	img {
		border: 0;
	}

	@media only screen and (min-width: $viewport-large) {
		margin: 0;
	}
}

#main-footer .footer-bottom {
	margin-top: 60px;
	text-align: center;

	@media only screen and (min-width: $viewport-large) {
		margin-top: 80px;
		text-align: left;
	}
}
