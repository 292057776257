
* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
  
html {
	font-size: 62.5%;
}

body {
	color: $black;
	font-family: $base-font;
	font-size: 1.6rem;
	line-height: 1.7;
	
	// @media screen and (min-width: 1200px) {
	// 	background-image: $svg-bg-spotlight-left, $svg-bg-spotlight-right;
	// 	background-repeat: no-repeat, no-repeat;
	// 	background-position: top left, top right;
	// }
}

h1 {
	font-family: $title-font;
	font-size: 5.6rem;
	font-weight: $ft-weight-bold;
	line-height: 1.2;
	margin-bottom: 0;
}

.product-page h1 {
	font-family: $title-font;
	font-size: 4rem;
	font-weight: 600;
	margin: 1rem 0;
}

h2 {
	font-family: $title-font;
	font-size: 4.2rem;
	font-weight: $ft-weight-bold;
	margin: 3rem 0 -.5rem;
	line-height: 1.2;
}

h3 {
	font-family: $title-font;
	font-size: 1.6rem;
	font-weight: $ft-weight-semi-bold;
	margin: 2rem 0;
	line-height: 1.2;
}

p {
	margin: 1.5em 0;
}

a {
	color: inherit;

	&:hover {
		text-decoration: none;
	}
}

p > a {
	font-weight: 600;
}

small {
	font-size: 1.2rem;
	font-family: $title-font;
	font-style: italic;
}

img {
	border: 6px solid $black;
}

::selection {
	background-color: $gcGeel;
}

.inner {
	margin: 0 $col_margin;

	@media screen and (min-width: $viewport-large) {
		margin: 0 auto;
		max-width: $viewport-large;
	}
}

.center {
	text-align: center;
}

address {
	font-style: normal;
	font-weight: 600;
	font-size: 1.2rem;
	margin: 1.5rem 0;
	line-height: 1.6;
}

$fiche-padding: 4px;
.fiche {
	background: transparent url('./../images/bb-pattern.gif') 0 0 repeat;
    background-size: 1280px;
	border: 3px solid $black;
	border-bottom: 6px solid $black;
	font-size: 1.3rem;
	font-weight: 600;
	margin: 2rem 0;
	padding: 0 8px;

	h2 {
		font-size: 2.1rem;
		font-weight: 600;
		margin: 15px 0;
	}

	table {
		border-collapse: collapse;
		width: 100%;
	}

	td {
		padding: $fiche-padding 0;
		width: 50%;
	}

	td[colspan="2"] {
		width: 100%;
	}

	td + td {
		border-left: 3px solid $black;
		padding-left: 8px;
	}

	tr {
		border-bottom: 3px solid $black;
	}

	tr:last-child{
		border-bottom: 0;
	}

	dl, dt, dd {
		margin: 0;
		padding: 0;
	}

	dl {
		display: flex;
		flex-flow: row wrap;
		margin: 0;
		padding: 0;
	}

	dt {
		font-size: 1.2rem;
		padding: $fiche-padding 0;
		text-transform: uppercase;
		width: 100%;
	}

	dd {
		// border-top: 3px solid $black;
		padding: $fiche-padding 0;
		width: 50%;
	}

	dd + dd {
		// border-left: 3px solid $black;
		padding-left: 8px;
	}

	svg {
		margin-right: 10px;
	}

	td:first-child svg,
	dd:first-child svg {
		margin-left: 5px;
	}

	.nopadding {
		padding: 0;
	}

	.chart {
		text-align: center;
	}

	.chart svg {
		height: auto;
		margin: 0 auto !important;
		max-width: 300px;
		width: 100%;
	}
}

.radar-chart .area {
	// fill-opacity: ;
	stroke-width: 0;
}

.radar-chart .axis .legend {
	font-family: $base-font;
	font-size: 1.3rem;
	font-weight: 600;
}

.radar-chart .level,
.radar-chart .axis line {
	stroke: $gray;
	stroke-width: 1;
	z-index: 999;
}

$margin-float-image: 150px;
.product-page {
	.float-top {
		margin-top: -#{$margin-float-image};
		margin-bottom: $margin-float-image;
	}

	.header-bottom {
		margin: 30px 0;
	}

	.header-top,
	.header-side {
		text-align: center;
	}
	
	.header-side img {
		display: block;
		margin: 0 auto;
	}

	#main-header {
		padding-bottom: $margin-float-image;
	}

	#main-header svg {
		margin-left: auto;
		margin-right: auto;
	}

	@media screen and (min-width: $viewport-large) {
		.header-bottom {
			margin: 0;
		}

		.header-top,
		.header-side {
			text-align: left;
		}

		#main-header {
			padding-bottom: $margin-float-image + 30px;
		}

		#main-header svg {
			margin-left: 0;
			margin-right: 0;
		}
	}

	main .col {
		margin-bottom: 60px;
	}

	@media screen and (min-width: $viewport-large) {
		main .col {
			margin-bottom: 80px;
		}	
	}
}
