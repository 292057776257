.card {
    box-shadow: 0 6px 0 0 $black, 0 3px 0 0 transparent;
    margin: 90px 0;
    transition: all 0.2s ease;
    transform: translateZ(0);

    img {
        border: 0;
        outline: 3px solid $black;
        outline-offset: -3px;
        display: block;
    }

    @media only screen and (min-width : $viewport-large) {
        &:hover {
            box-shadow: 0 9px 0 0 $black;
            transform: translateY(-3px);
            margin-top: -3px;
        }
    }
}

.card-item {
    &:nth-child(even)::before {
        background-image: $svg-barrel-left;
        background-size: 26px 40px;
        content: "";
        display: block;
        width: 26px;
        height: 40px;
        position: absolute;
        left: auto;
        right: 0;
        transform: scaleX(-1);
    }

    &:nth-child(odd)::before {
        background-image: $svg-barrel-left;
        background-size: 26px 40px;
        content: "";
        display: block;
        width: 26px;
        height: 40px;
        position: absolute;
        left: 0;
        right: auto;
        transform: scaleX(1);
    }

    @media only screen and (min-width : $viewport-large) {
        &::before,
        &:nth-child(even)::before,
        &:nth-child(odd)::before {
            content: none;
            transform: none;
            display: none;
        }

        &:nth-child(3n+1)::before {
            background-size: 39px 65px;
            content: "";
            display: block;
            width: 39px;
            height: 65px;
            position: absolute;
            left: auto;
            right: 0;
            transform: scaleX(-1);
        }

        &:nth-child(6n+1)::before {
            background-size: 39px 65px;
            content: "";
            display: block;
            width: 39px;
            height: 65px;
            position: absolute;
            left: 0;
            right: auto;
            transform: scaleX(1);
        }
    }
}

.card--product {
    h3 {
        font-family: $title-font;
        font-size: 4rem;
        font-weight: 600;
        margin: 1rem 0;
    }

    .card-body {
        padding: 20px 10px;
    }
}

.product-info {
    .brewery {
        font-family: $base-font;
        font-size: 1.8rem;
        font-weight: 700;
        line-height: 1.2;
        margin: 0 0 1rem;
    }

    .alcohol {
        font-family: $title-font;
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 1.5;
        margin: 1rem 0;
    }

    .date {
        font-family: $base-font;
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 1.5;
        margin: 1rem 0;
        
        strong {
            font-weight: 600;
        }
    }
}

.card.disabled {
    background-color: $yellow_light;
    color: $gray;

    a {
        color: $gray;
    }
}

.card-list {
    @media only screen and (min-width : $viewport-large) {
        display: grid;
        gap:  90px 20px;
        grid-template-columns: repeat(3, minmax(300px, 1fr));

        .card {
            margin: 0;
        }
    }
}

.js-clickcard {
    cursor: pointer;

    a {
        text-decoration: none;
    }
}