/* Old one */
.paginator {
	clear: both;
	margin: 2em 0;
	text-align: center;

	a,
	.current {
        display: inline-block;
		padding: .5em;
	}
	.current {
        background-color: #000;
		color: #fff;
	}
}

/* Pagination */
.paginate {
    display: flex;
    justify-content: center;
    margin-bottom: 4em;
    margin-top: 6em;
    position: relative;
}

.paginate-button {
    font-size: 1.5rem;
    font-weight: $ft-weight-bold;
    padding: 20px;
    margin: 20px 10px;
    display: none;
}

.paginate-first,
.paginate-last,
.paginate-prev,
.paginate-next {
    background-color: $white;
    box-shadow: inset 0 0 0 3px $button-border, 0 3px 0 $button-border;
    display: block;
    text-align: center;
    text-decoration: none;

    &:hover {
        box-shadow: inset 0 0 0 3px $button-border;
        transform: translateY(3px);
    }
}

.paginate-button {
    &.paginate-first,
    &.paginate-last,
    &.paginate-prev,
    &.paginate-next {
        display: block;
        display: flex;
    }
}

.paginate .pagenumbers {
    display: flex;
}

@media only screen and (min-width : 1200px) {
    .paginate {
        align-content: initial;
    }

    .paginate-button {
        display: block;
        margin: 20px 0;
    }

    .paginate-first,
    .paginate-prev {
        position: absolute;
        left: 0;
    }

    .paginate-last,
    .paginate-next {
        position: absolute;
        right: 0;
    }
}
