/* Form items */
$form-color: $groeiDark;
$form-border: $groeiDark;
$form-border-error: $error;
$form-active: $blue_light;
$form-border-radius: 5px;
$form-border-width: 2px;
$form-border-inner-width: 6px;

label {
	display: block;
	margin: 1em 0;
}

input[type=text],
input[type=email],
input[type=password],
input[type=search],
textarea {
	border: 0;
	border-radius: $form-border-radius;
	display: block;
	width: 100%;
	margin: 1em 0;
	padding: 0.8em 0.5em;
	box-shadow: inset 0 ($form-border-inner-width + $form-border-width) ($form-border-inner-width + $form-border-width) rgba($form-border, 0.20), 
	inset 0 0 0 $form-border-width $form-border;

	&:focus {
		// background-color: rgba($form-active, 0.1);
		box-shadow: inset 0 ($form-border-inner-width + $form-border-width) ($form-border-inner-width + $form-border-width) rgba($form-border, 0.20), 
		inset 0 0 0 $form-border-width $form-border, 0 0 0 3px $form-active;
		outline: none;
	}
}

input[type=search]::-webkit-search-cancel-button {
	-webkit-appearance: none;
	
	background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath d='M16,4C9.383,4,4,9.383,4,16s5.383,12,12,12s12-5.383,12-12S22.617,4,16,4 M16,2c7.732,0,14,6.268,14,14s-6.268,14-14,14 S2,23.732,2,16S8.268,2,16,2L16,2z M19.536,11.05l1.414,1.414L17.414,16l3.536,3.536l-1.414,1.414L16,17.414l-3.536,3.536 l-1.414-1.414L14.586,16l-3.536-3.536l1.414-1.414L16,14.586L19.536,11.05z'/%3E%3C/svg%3E");
	width: 24px;
	height: 24px;
}

label + input {
	margin-top: -1em;
}

.frontend-admin-page {
	.form-container {
		max-width: 600px;
	}
}

input[type=submit],
button[type=submit],
button {
    background-color: $white;
	border: 0;
	border-radius: 0;
    box-shadow: inset 0 0 0 3px $button-border, 0 3px 0 $button-border;
    display: block;
    font-weight: $ft-weight-bold;
    font-size: 1.5rem;
    padding: 20px;
    margin: 20px 0;
    text-align: center;
    text-decoration: none;

    &:hover,
	&:active {
        box-shadow: inset 0 0 0 3px $button-border;
        transform: translateY(3px);
    }
}

/* Styled select */
.select-css {
    appearance: none;
	background-color: transparent;
	background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' wiid='C292'%3E%3Cpath d='M12.173 14.414L7.5 9.704 8.21 9l3.972 3.996L16.21 9l.704.71z' fill='currentColor' fill-rule='evenodd' wiid='C293'%3E%3C/path%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: right 8px top 50%;
	background-size: 24px;
    border: 0;
	box-shadow: inset 0 0 0 4px $form-border;
	display: block;
	line-height: 1.3;
	padding: 1em 2em 1em .8em;
	width: auto;
	max-width: 100%;
    margin: 0;
}

.select-css:disabled {
	box-shadow: inset 0 0 0 4px $gray;
	color: $gray;
}

.select-css::-ms-expand {
	display: none;
}

.select-css:hover {
	border-color: #888;
}

.select-css:focus {
    // background-color: rgba($blue_light, $alpha);
    outline: none;
}

.select-css option {
	font-weight:normal;
}

.form-help {
	font-size: 1.5rem;
	margin: 6rem 0;
}

.form-item {
	margin: 1em 0;
}

.form-item.error {
	label {
		color: $error;
	}

	input[type=text],
	input[type=password],
	input[type=search],
	input[type=email],
	textarea {
		box-shadow: inset 0 ($form-border-inner-width + $form-border-width) ($form-border-inner-width + $form-border-width) rgba($form-border, 0.20), 
		inset 0 0 0 ($form-border-width * 2) $form-border-error;
	
		&:focus {
			// background-color: rgba($form-active, 0.1);
			box-shadow: inset 0 ($form-border-inner-width + $form-border-width) ($form-border-inner-width + $form-border-width) rgba($form-border, 0.20), 
			inset 0 0 0 ($form-border-width * 2) $form-border-error, 0 0 0 3px $form-active;
			outline: none;
		}
	}
}

.errors {
	color: $error;
	font-size: 1.5rem;
}

.cols-2 .form-item {
	@media screen and (min-width: $viewport-medium) {
		margin-bottom: 0;

		input {
			margin-bottom: 0;
		}
	}	
}

.form-item--label-before {
	label {
		padding-right: 20px;
	}

	label,
	select,
	input {
		display: inline-block;
	}
}

.search-form {
	margin: 3rem 0;
}

@media screen and (min-width: $viewport-medium) {
	form > .form-title:first-of-type {
		margin-top: 0;
	}
}

/* Form Counter */
.form-title::before {
	background-color: $groeiGeel;
	border-radius: 50%;
	display: block;
	line-height: 55px;
	margin-right: 20px;
	margin-bottom: 1rem;
	text-align: center;
	width: 55px;

	@media screen and (min-width: $viewport-medium) {
		display: inline-block;
		margin-bottom: 0;
	}
}

.form-counter {
	counter-reset: h2counter; /* Set a counter named 'section', and its initial value is 0. */

	.form-title::before {
		content: counter(h2counter);
		counter-increment: h2counter;
	}
}

.form-select-image {
	label {
		border-radius: 20px;
		display: inline-block;
		position: relative;
		margin: 0;
	}

	img {
		border-radius: 20px;
		display: block;
	}

	input[type="radio"] {
		opacity: 0;
		position: fixed;
		width: 0;
	}
	
	input[type="radio"]:checked + label::after,
	input[type="radio"]:focus + label::after {
		content: "";
		background-image: $svg-select-checkmark;
		background-repeat: no-repeat;
		background-color: transparent;
		position: absolute;
		top: -11px;
		right: -11px;
		width: 29px;
		height: 29px;
	}

	input[type="radio"]:checked + label::before,
	input[type="radio"]:focus + label::before {
		border-radius: 14px;
		box-shadow: inset 0 0 0 7px $selection;
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	// input[type="radio"]:checked + label img,
	// input[type="radio"]:focus + label img {
	// 	// border: 1px inset transparent;
	// }
}

/* 
  Custom checkbox
  https://css-tricks.com/the-checkbox-hack/
*/

/* Base for label styling */
.custom-checkbox,
.fui-checkbox {
    [type="checkbox"]:not(:checked),
    [type="checkbox"]:checked {
        position: absolute;
        left: -9999px;
    }
    [type="checkbox"]:not(:checked) + label,
    [type="checkbox"]:checked + label {
        position: relative;
        padding-left: 40px;
        cursor: pointer;
    }

    /* checkbox aspect */
    [type="checkbox"]:not(:checked) + label:before,
    [type="checkbox"]:checked + label:before {
        content: '';
        position: absolute;
        left: 0; 
        top: 0;
        width: 24px; 
        height: 24px;
        background: $white;
        border: 1px solid $black;
        border-radius: 0;
        box-shadow: $shadow-ui-up;
    }

    /* checked mark aspect */
    [type="checkbox"]:not(:checked) + label:after,
    [type="checkbox"]:checked + label:after {
        content: '';
        background-image: $svg-select-checkmark;
		background-repeat: no-repeat;
		background-position: center;
        display: block;
        position: absolute;
        top: 0; 
        left: 0;
        font-size: 0.833333333rem; // 15px
        line-height: 0.8;
        transition: all .1s;
        width: 24px;
        height: 24px;
    }
    /* checked mark aspect changes */
    [type="checkbox"]:not(:checked) + label:after {
        opacity: 0;
    }
    [type="checkbox"]:checked + label:after {
        opacity: 1;
    }
    /* disabled checkbox */
    [type="checkbox"]:disabled:not(:checked) + label:before,
    [type="checkbox"]:disabled:checked + label:before {
        box-shadow: none;
        border-color: #bbb;
        background-color: #ddd;
    }
    [type="checkbox"]:disabled:checked + label:after {
      color: $black;
    }
    [type="checkbox"]:disabled + label {
        color: #aaa;
    }
    /* accessibility */
    [type="checkbox"]:checked:focus + label:before,
    [type="checkbox"]:not(:checked):focus + label:before {
		outline: 1px solid $focus-color;
    }

    /* hover style just for information */
    label:hover:before {
        border: 2px solid $black!important;
    }
}

.custom-radio {
    [type="radio"]:not(:checked),
    [type="radio"]:checked {
        position: absolute;
        left: -9999px;
    }
    [type="radio"]:not(:checked) + label,
    [type="radio"]:checked + label {
        position: relative;
        padding-left: 40px;
        cursor: pointer;
    }

    /* radio aspect */
    [type="radio"]:not(:checked) + label:before,
    [type="radio"]:checked + label:before {
        content: '';
        position: absolute;
        left: 0; 
        top: 0;
        width: 24px; 
        height: 24px;
        background: $white;
        border: 1px solid $black;
        border-radius: 0;
        box-shadow: $shadow-ui-up;
    }

    /* checked mark aspect */
    [type="radio"]:not(:checked) + label:after,
    [type="radio"]:checked + label:after {
        content: '';
        background-image: $svg-select-checkmark;
		background-repeat: no-repeat;
		background-position: center;
        display: block;
        position: absolute;
        top: 0; 
        left: 0;
        font-size: 0.833333333rem; // 15px
        line-height: 0.8;
        transition: all .1s;
        width: 24px;
        height: 24px;
    }
    /* checked mark aspect changes */
    [type="radio"]:not(:checked) + label:after {
        opacity: 0;
    }
    [type="radio"]:checked + label:after {
        opacity: 1;
    }
    /* disabled checkbox */
    [type="radio"]:disabled:not(:checked) + label:before,
    [type="radio"]:disabled:checked + label:before {
        box-shadow: none;
        border-color: #bbb;
        background-color: #ddd;
    }
    [type="radio"]:disabled:checked + label:after {
      color: $black;
    }
    [type="radio"]:disabled + label {
        color: #aaa;
    }
    /* accessibility */
    [type="radio"]:checked:focus + label:before,
    [type="radio"]:not(:checked):focus + label:before {
		outline: 1px solid $focus-color;
    }

    /* hover style just for information */
    label:hover:before {
        border: 2px solid $black!important;
    }
}

/*
  Custom multi-select
*/
.multi-select-dropdown {
    width: 100%;

    button {
        background-color: $white;
    	box-shadow: inset 0 0 0 3px $button-border, 0 3px 0 $button-border;
        color: $black;
        font-weight: $ft-weight-bold;
		font-size: 1.5rem;
        line-height: 1.16;
        padding: 0.5em 2.9em 0.5em 0.8em;
        margin: 0;
        min-height: 60px;
        user-select: none;
        width: 100%;
        text-align: left;

		&:hover,
		&:active {
			box-shadow: inset 0 0 0 3px $button-border;
			transform: translateY(3px);
		}
    }

	label {
		text-align: left;
	}

    .multi-select-list {
        background-color: $white;
        border: 3px solid $button-border;
		// height: 480px;
		max-height: 480px;
		min-height: 250px;
        overflow: auto;
        padding: 1em 2.9em 1em 0.8em;
        width: 100%;
    }

    @media only screen and (min-width: $viewport-large) {
        button {
            background-image: $svg-arrow-down;
            background-repeat: no-repeat, repeat;
            background-position: right 1em top 40%;
			border-radius: 0;
			box-shadow: inset 0 0 0 3px $button-border, 0 3px 0 $button-border;
        }

        .hidden {
            display: none;
        }

        .multi-select-list {
            position: absolute;
            max-width: 300px;
            width: 300px;
            z-index: z-index(navigation);

            .custom-checkbox:first-child label {
                margin-top: 0;
            }
        }
    }
}
