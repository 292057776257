#main-header {
    background: transparent url('./../images/bb-pattern.gif') 0 0 repeat;
    background-size: 1280px;
    border-bottom: 6px solid $black;
    overflow: hidden;
    margin-bottom: 30px;
    padding-bottom: 80px;

    img {
        border: 0;
        border-bottom: 6px solid $black;
    }

	svg {
		display: block;
		margin: 60px 0;
	}
}