@media only screen and (min-width : $viewport-large) {
    .col > *:first-child {
        margin-top: 0;
    }

    .col > *:last-child {
        margin-bottom: 0;
    }
}

.row {
    margin: 0 $col_margin;

    @media only screen and (min-width : $viewport-large) {
        display: flex;
        margin: 0;

        &.inner {
            margin: 0 auto;
        }
    }
}

.grid-cols-2-right {
    .col:nth-child(2) {
        margin: 0 -#{$col_margin};
    }

    @media only screen and (min-width: $viewport-large) {
        display: grid;
        gap: 0 20px;
        grid-template-columns: calc((100% - 980px) / 2) 460px auto;
    
        .col:nth-child(1) {
            grid-column: 2 / 3;
        }

        .col:nth-child(2) {
            grid-column: 3;
            margin: 0;
        }

        .col *:first-child {
            margin-top: 0;
        }
    }
}

.grid-cols-1-3 {
    .header-side {
        align-self: center;
        margin: 0 -#{$col_margin};
    }

    @media only screen and (min-width: $viewport-large) {
        display: grid;
        gap: 0 20px;
        grid-template-columns: 380px auto;
        grid-template-areas: "header sidebar"
        "footer sidebar";

        .header-top {
            grid-area: header;
        }

        .header-bottom {
            align-self: end;
            grid-area: footer;
        }

        .header-side {
            align-self: auto;
            grid-area: sidebar;
            margin: 0;
        }
    }
}

.grid-cols-3-1-wide {
    @media only screen and (min-width: $viewport-large) {
        display: grid;
        gap: 0 100px;
        grid-template-columns: 540px auto;
    }
}

.grid-cols-3 {
	@media only screen and (min-width: $viewport-large) {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
	}
}

.grid-cols-3--footer {
	display: grid;
    grid-template: 
        "address sponsors"
        "social social";

    .col:nth-child(1) {
        grid-area: address;
    }

    .col:nth-child(2) {
        grid-area: social;
    }

    .col:nth-child(3) {
        grid-area: sponsors;
    }

    @media only screen and (min-width: $viewport-large) {
        grid-template: 
        "address social sponsors";
	}
}

.flex-seperate {
    @media only screen and (min-width: $viewport-large) {
        display: flex;
        justify-content: space-between;
    }
}