.message {
    background-color:rgba($groeiGeel, 0.6);
    border: 4px solid $groeiGeel;
    border-radius: 5px;
    margin: 5rem 0;
    padding: 2rem;
    font-size: 1.5rem;

    @media screen and (min-width: $viewport-medium) {
        margin: 0 0 5rem;
    }
}

.message.error {
    background-color:rgba($error, 0.6);
    border: 4px solid $error;
}