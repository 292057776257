@mixin anti-alias {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* z-index management: https://jonsuh.com/blog/organizing-z-index-with-sass/ */
$z-index: (
  banner             : 300,
  modal              : 200,
  navigation         : 100,
  footer             : 90,
);

@function z-index($key) {
    @return map-get($z-index, $key);
}

@function page-margin() {
    @return calc((100% - #{$page-width}) / 2);
}
