.main-filter-menu {
	background-color: $white;
	display: none;

	ul {
		position: relative;
		margin: 0;
		padding: 10px 0;
		list-style-type: none;
	}

	li {
		margin: 0;
		padding: 10px 0;
	
		a {
			display: block;
			padding: 4px 0;
		
			&:hover {
				background-color: transparent !important;
				text-decoration: underline;
			}
		}
	}

	@media only screen and (min-width : $viewport-large) {
		display: block;

		ul {
			display: flex;

			li {
				margin-bottom: 0;
				margin-right: 30px;
				padding: 0;
				width: auto;
			}	
		}
	}
}

.main-menu {
	display: none;
	overflow: hidden;
	
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		width: 100%;

		li {
			display: flex;
			margin-bottom: 10px;
		}

		a {
			align-items: center;
			display: flex;
			padding: 10px 0;
			width: 100%;
		}

		a:hover {
			background-color: transparent !important;
			text-decoration: underline;
		}
	}

	@media only screen and (min-width : $viewport-large) {
		display: flex !important;

		ul {
			display: flex;

			a.active {
				border-bottom: 3px solid $black;
			}

			li {
				margin-bottom: 0;
				margin-right: 30px;
				width: auto;
			}

			li:last-child {
				margin-right: 0;
			}

			li.menu-separator {
				margin-left: auto;
			}
		}
	}
}

/* Menu-button */
.menu-button {
	position: absolute;
	top: 10px;
	right: 10px;
	width: 16px;
	height: 16px;
	display: block;

	span {
		display: block;
		position: relative;
		top: 6px;
		overflow: visible;
	}
	
	span,
	span:before,
	span:after {
		background-color: #000;
		display: block;
		width: 16px;
		height: 16px;
		height: 3px;
		pointer-events: none;
	}

	span:before {
		content: "";
		position: absolute;
		top: -5px;
	}
	
	span:after {
		content: "";
		position: absolute;
		bottom: -5px;
	}

	&:hover {
		background-color: transparent !important;
		span,
		span:before,
		span:after {
			background-color: $gray;
		}
    }
    
    @media only screen and (min-width : $viewport-large) {
        display: none;
    }
}

/* Mobile Menu States */
.mmenu-open {
	// background-color: $blue_light;

	#content {
		display: none;
	}

	.main-filter-menu {
		display: block;
	}

	.main-menu {
		display: block;
	}

	.social-links {
		display: block;
	}

	.sub-menu {
		border-top: 2px solid $black;
		padding-top: 10px;
		margin-top: 10px;
	}

	@media only screen and (min-width : $viewport-large) {
		background-color: transparent;

		#content {
			display: block;
		}
	}
}


.nav-tab {
	margin-bottom: 10px;

	.nav-item {
		// background-color: $yellow_light;
		border: 3px solid $black;
		display: block;
		padding: 4px 10px;
		margin-bottom: 4px;
	}

	.nav-item:last-child {
		margin-bottom: 0;
	}

	.nav-item.active {
		background-color: $black;
		color: $white;
	}

	@media only screen and (min-width : $viewport-small) {
		display: flex;
		align-items: center;

		.nav-item {
			margin: 0 5px 0 0;
		}
	}
}

.filter-menu {
	margin: 0;
	position: relative;

	.icon {
		width: 20px;
	}

	ul {
		margin: 0;
		padding: 0;
		position: relative;
		list-style-type: none;
	}

	li {
		font-size: 16px;
		letter-spacing: .1em;
		line-height: 2;
		margin: 0;
		padding: 0;
		text-transform: uppercase;

		a {
			color: #000;
			display: inline-block;
			text-decoration: none;
		}

		li {
			font-family: $base-font;
			font-weight: 600;
			letter-spacing: .1em;
			padding-left: 1rem;
			text-transform: none;
		}

		li a::before {
			content: "+ ";
		}

		li li a::before {
			content: "- ";
		}
    }

	li.active {
		font-family: $base-font;
		font-weight: 700;
	}

    @media only screen and (min-width : $viewport-large) {
		.menu-items {
			display: block;
		}

		.filter-button {	
			display: none;
		}
    }
}

.filter {
	margin: 60px auto;
	text-align: center;
}

.horzintal-filter-menu {
	margin: 0 -10px 40px;
	overflow-y: hidden;
	font-size: 1.8rem;

	ul {
		display: flex;
		margin: 0 0 -25px;
		overflow-x: scroll;
		padding: 0 0 25px;
		list-style-type: none;
		white-space: nowrap;
	}

	li {
		margin: 0;
		padding: 10px 10px 0 0;
		
		&:first-child {
			padding-left: 10px;
		}

		a {
			align-items: center;
			border: 3px solid transparent;
			display: flex;
			justify-content: center;
			flex-direction: column;
			font-weight: 600;
			margin-bottom: 3px;
			padding: 12px 12px !important;
			position: relative;
			height: 55px;

			&:hover {
				border: 3px solid $black;
			}
			
			&.active {
				border: 3px solid $black;
				box-shadow: 0 3px 0 0 $black;
				margin-bottom: 0;
				text-decoration: none;
				font-size: 21px;
				font-weight: 700;
				transform: translateY(-3px);
			}
		}

		.label {
			align-items: center;
			display: flex;
			justify-content: center;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			text-decoration: underline;
		}

		a:hover .label,
		.active .label {
			text-decoration: none;
		}

		.label-clone {
			align-items: center;
			display: flex;
			font-size: 21px;
			font-weight: 700;
			justify-content: center;
			visibility: hidden;
		}
	}

	@media only screen and (min-width : $viewport-large) {
		ul {
			justify-content: center;
		}
	}
}